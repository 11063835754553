<template>
<transition name="fade">
  <div class="sel-wrap px-16 pb-8 pt-16">
    <div class="sel-item br-lg"
      v-for="(item, index) in studies" :key="item.value"
      :class="{'mr-8': index % 2 ===0, 'active': select.value === item.value}"
      @click="handleSelect(item)"
    >
      {{item.label}}
      <div v-if="select.value === item.value" class="sel-icon df-middle df-center">
        <Pic src="trade/check" width="16" height="16" />
      </div>
    </div>
  </div>
</transition>
</template>

<script lang="ts">
import {
  readOverlayStudies,
  readPanelStudies,
  StudyConfig,
} from '@/modules/market/components/chart/chartConfig'
import * as R from 'ramda'
import { defineComponent, shallowRef } from 'vue'
import Pic from '@/components/Pic.vue'

export default defineComponent({
  name: 'SelectDialog',
  components: {
    Pic,
  },
  props: {
    active: Number,
  },
  setup (props, ctx) {
    const all = readOverlayStudies().concat(readPanelStudies())
    const studies = R.reject(R.propSatisfies(v => v === false, 'configurable'), all)

    const select = shallowRef<StudyConfig>(studies[0])

    const handleSelect = (item: StudyConfig) => {
      select.value = item
      ctx.emit('update:study', item)
    }

    handleSelect(select.value)

    return {
      studies,
      select,
      handleSelect,
    }
  },
})
</script>

<style lang="scss" scoped>
.sel-wrap {
  position: absolute;
  top: 44px;
  left: 0;
  width: 100%;
  background: var(--color-white);
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.3);
  border: 1px solid #D5DEE8;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;

  .sel-item {
    width: calc(50% - 4px);
    height: 36px;
    line-height: 36px;
    text-align: center;
    background: var(--color-light);
    margin-bottom: 8px;
    position: relative;

    &.active {
      border: 1px solid var(--color-primary);
      background: var(--color-primary-thin);
      color: var(--color-black);
    }

    .sel-icon {
      position: absolute;
      height: 16px;
      width: 16px;
      top: -8px;
      right: -8px;
      background-color: var(--color-primary);
      border-radius: 50%;
    }
  }
}
</style>
