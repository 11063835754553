
import { StudyConfig } from '@/modules/market/components/chart/chartConfig'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import Button from '@/components/Button.vue'
import SelectDialog from '@/pages/setIndicators/components/SelectDialog.vue'
import MA from '@/pages/setIndicators/components/MA.vue'
import EMA from '@/pages/setIndicators/components/EMA.vue'
import BOLL from '@/pages/setIndicators/components/BOLL.vue'
import SAR from '@/pages/setIndicators/components/SAR.vue'
import MACD from '@/pages/setIndicators/components/MACD.vue'
import Stoch from '@/pages/setIndicators/components/Stoch.vue'
import RSI from '@/pages/setIndicators/components/RSI.vue'
import CCI from '@/pages/setIndicators/components/CCI.vue'
import Pic from '@/components/Pic.vue'
import { computed, defineComponent, shallowRef } from 'vue'
import { StudyType } from 'happychart'

const components = {
  [StudyType.SMA]: MA,
  [StudyType.EMA]: EMA,
  [StudyType.BOLL]: BOLL,
  [StudyType.SAR]: SAR,
  [StudyType.MACD]: MACD,
  [StudyType.KDJ]: Stoch,
  [StudyType.RSI]: RSI,
  [StudyType.CCI]: CCI,
}

const introduce = {

}

export default defineComponent({
  name: 'SetIndicators',
  components: {
    PageWithHeader,
    Button,
    SelectDialog,
    Pic,
  },
  setup () {
    const openSelect = shallowRef(false)
    const select = shallowRef<StudyConfig | null>(null)
    const component = computed(() => select.value ? components[select.value?.value as keyof typeof components] : null)

    return {
      select,
      component,
      openSelect,
    }
  },
})
