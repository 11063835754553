
import TheContainer from '@/pages/setIndicators/components/TheContainer.vue'
import { StudyType } from 'happychart/types'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheStoch',
  components: { TheContainer },
  data () {
    return {
      type: StudyType.KDJ,
    }
  },
})
