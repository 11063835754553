<template>
<TheContainer :type="type" #="{ input }">
  <div class="item df-middle">
    <t path="indicatorconfig_13" class="c-title f-md">%K length</t>
    <input class="br-lg flex-1 px-16 f-bold" type="number" v-model.number="input.period">
  </div>
  <div class="item df-middle">
    <t path="indicatorconfig_14" class="c-title f-md">%K Smoothing</t>
    <input class="br-lg flex-1 px-16 f-bold" type="number" v-model.number="input.slowPeriod">
  </div>
  <div class="item df-middle">
    <t path="indicatorconfig_15" class="c-title f-md">%D Smoothing</t>
    <input class="br-lg flex-1 px-16 f-bold" type="number" v-model.number="input.smoothPeriod">
  </div>
  <t as="p" class="f-bold mb-8" style="margin-top: 40px;" path="indicatorconfig_29"></t>
  <div class="f-md" style="line-height: 18px;">
    <t path="indicatorconfig_30" multiline custom #="{td}">
      <p v-for="(p, index) in td" :key="index">{{p}}</p>
    </t>
  </div>
</TheContainer>
</template>

<script lang="ts">
import TheContainer from '@/pages/setIndicators/components/TheContainer.vue'
import { StudyType } from 'happychart/types'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheStoch',
  components: { TheContainer },
  data () {
    return {
      type: StudyType.KDJ,
    }
  },
})
</script>

<style lang="scss" scoped>
.item {
  height: 40px;
  margin-bottom: 12px;

  span {
    width: 100px;
  }

  input {
    height: 40px;
    border: 1px solid #D5DEE8;
    background-color: var(--color-background);
  }
}
</style>
