
import Button from '@/components/Button.vue'
import Pic from '@/components/Pic.vue'
import { showAlert } from '@/components/popup/popup'
import { readStudyConfig, saveStudyConfig } from '@/modules/market/components/chart/chartConfig'
import { StudyType } from 'happychart/types'
import { defineComponent, reactive } from 'vue'

export default defineComponent({
  name: 'TheContainer',
  components: {
    Button,
    Pic,
  },
  emits: ['reset', 'save'],
  props: {
    type: {
      type: Number,
      required: true,
    },
  },
  setup (props) {
    const userConfig = readStudyConfig(props.type as StudyType)

    const config = reactive({
      input: userConfig?.input,
    })

    const reset = () => {
      const origin = readStudyConfig(props.type as StudyType, true)
      if (origin) {
        config.input = origin.input
        if (userConfig) {
          userConfig.input = origin.input
        }
        saveStudyConfig(origin)
        showAlert('Configuration has been reset!')
      }
    }

    const save = () => {
      if (userConfig) {
        saveStudyConfig(userConfig)
        showAlert('Configuration Saved!')
      }
    }

    return {
      config,
      reset,
      save,
    }
  },
})
