<template>
<TheContainer :type="type" #="{ input }">
  <div v-for="(item, index) in input.series" :key="index" class="ma-item df-middle f-md">
    <div class="check-wrap mr-12" :class="{'active': !item.disabled}"
      @click="item.disabled = !item.disabled" />
    <span>EMA {{index + 1}}</span>
    <div class="periods flex-1 df-center df-middle">
      <t path="indicatorconfig_5" class="mr-8 c-title" :class="{'c-tip': item.disabled }">Periods</t>
      <input type="number" class="f-bold px-8 f-nm bg-background" :class="{'c-title': item.disabled }"
        v-model.number="item.period">
    </div>
    <div class="color-wrap df-middle">
      <t path="indicatorconfig_6" class="c-title" :class="{'c-tip': item.disabled}">Color</t>
      <span class="color br-sm ml-8" :style="`background: ${item.lineColor};`"></span>
    </div>
  </div>
  <t as="p" class="f-bold mb-8" style="margin-top: 40px;" path="indicatorconfig_21"></t>
  <div class="f-md" style="line-height: 18px;">
    <t path="indicatorconfig_22" multiline custom #="{td}">
      <p v-for="(p, index) in td" :key="index">{{p}}</p>
    </t>
  </div>
</TheContainer>
</template>
<script lang="ts">
import TheContainer from '@/pages/setIndicators/components/TheContainer.vue'
import { StudyType } from 'happychart/types'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheEMA',
  components: { TheContainer },
  data () {
    return {
      type: StudyType.EMA,
    }
  },
})
</script>

<style lang="scss" scoped>
.ma-item {
  height: 30px;
  margin-bottom: 12px;

  .check-wrap {
    height: 16px;
    width: 16px;
    border: 1px solid #D5DEE8;
    border-radius: 50%;
    background-color: rgba(213, 222, 232, 0.5);

    &.active {
      background-color: var(--color-white);
      background-image: url(/img/trade/check_b@2x.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: 16px 16px;
    }
  }

  .periods {
    input {
      width: 48px;
      height: 28px;
      border-radius: 3px;
      border: 1px solid #D5DEE8;
    }
  }

  .color-wrap {
    .color {
      width: 16px;
      height: 16px;
      display: inline-block;
    }
  }
}
</style>
