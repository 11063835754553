/**
*  TheContainer.vue of project wetrade
*  @date 2022/5/19 18:53
*  @author 阿佑[ayooooo@petalmail.com]
*/
<template>
<div class="df-col scroll-y">
  <div class="flex-1 scroll-y">
    <div class="px-16">
      <div class="df-between df-middle mt-32 mb-16" style="height: 40px">
        <t path="indicatorconfig_3" class="f-bold">Parameters</t>
        <div class="refresh" @click="reset">
          <t path="indicatorconfig_4" class="c-primary mr-4">Reset to default</t>
          <Pic src="trade/refresh" width="14" height="14" />
        </div>
      </div>
      <slot v-if="config.input" :input="config.input" />
    </div>
  </div>
  <div class="btm d-f">
    <Button class="flex-1" @click="$router.back()">
      <t path="indicatorconfig_35" class="f-bold f-lg">Cancel</t>
    </Button>
    <Button class="flex-2 primary" @click="save">
      <t path="indicatorconfig_36" class="f-bold f-lg">Save</t>
    </Button>
  </div>
</div>
</template>

<script lang="ts">
import Button from '@/components/Button.vue'
import Pic from '@/components/Pic.vue'
import { showAlert } from '@/components/popup/popup'
import { readStudyConfig, saveStudyConfig } from '@/modules/market/components/chart/chartConfig'
import { StudyType } from 'happychart/types'
import { defineComponent, reactive } from 'vue'

export default defineComponent({
  name: 'TheContainer',
  components: {
    Button,
    Pic,
  },
  emits: ['reset', 'save'],
  props: {
    type: {
      type: Number,
      required: true,
    },
  },
  setup (props) {
    const userConfig = readStudyConfig(props.type as StudyType)

    const config = reactive({
      input: userConfig?.input,
    })

    const reset = () => {
      const origin = readStudyConfig(props.type as StudyType, true)
      if (origin) {
        config.input = origin.input
        if (userConfig) {
          userConfig.input = origin.input
        }
        saveStudyConfig(origin)
        showAlert('Configuration has been reset!')
      }
    }

    const save = () => {
      if (userConfig) {
        saveStudyConfig(userConfig)
        showAlert('Configuration Saved!')
      }
    }

    return {
      config,
      reset,
      save,
    }
  },
})
</script>
