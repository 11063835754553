<template>
<PageWithHeader class="symbol">
  <template #title>
    <t path="indicatorconfig_2">Indicators Configuration</t>
  </template>

  <template #body>
    <div class="spread df-col">
      <div class="mx-16 select" @click="openSelect = !openSelect">
        <div class="sel-opt df-middle df-between">
          <div v-if="select" class="sel-info">
            <t path="" class="c-primary f-bold mr-8">{{select.label}}</t>
            <t path="" class="c-tip f-md">{{select.fullName}}</t>
          </div>
          <Pic src="trade/expand" width="16" height="16" />
        </div>
        <SelectDialog v-show="openSelect" v-model:study="select" />
      </div>
      <component v-if="component" :is="component" class="flex-1 fill" />
    </div>
  </template>
</PageWithHeader>

</template>

<script lang="ts">
import { StudyConfig } from '@/modules/market/components/chart/chartConfig'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import Button from '@/components/Button.vue'
import SelectDialog from '@/pages/setIndicators/components/SelectDialog.vue'
import MA from '@/pages/setIndicators/components/MA.vue'
import EMA from '@/pages/setIndicators/components/EMA.vue'
import BOLL from '@/pages/setIndicators/components/BOLL.vue'
import SAR from '@/pages/setIndicators/components/SAR.vue'
import MACD from '@/pages/setIndicators/components/MACD.vue'
import Stoch from '@/pages/setIndicators/components/Stoch.vue'
import RSI from '@/pages/setIndicators/components/RSI.vue'
import CCI from '@/pages/setIndicators/components/CCI.vue'
import Pic from '@/components/Pic.vue'
import { computed, defineComponent, shallowRef } from 'vue'
import { StudyType } from 'happychart'

const components = {
  [StudyType.SMA]: MA,
  [StudyType.EMA]: EMA,
  [StudyType.BOLL]: BOLL,
  [StudyType.SAR]: SAR,
  [StudyType.MACD]: MACD,
  [StudyType.KDJ]: Stoch,
  [StudyType.RSI]: RSI,
  [StudyType.CCI]: CCI,
}

const introduce = {

}

export default defineComponent({
  name: 'SetIndicators',
  components: {
    PageWithHeader,
    Button,
    SelectDialog,
    Pic,
  },
  setup () {
    const openSelect = shallowRef(false)
    const select = shallowRef<StudyConfig | null>(null)
    const component = computed(() => select.value ? components[select.value?.value as keyof typeof components] : null)

    return {
      select,
      component,
      openSelect,
    }
  },
})
</script>

<style lang="scss" scoped>
.sel-opt {
  height: 40px;
}

.select {
  position: relative;
  height: 40px;
  box-shadow: inset 0px -1px 0px 0px var(--color-border);
}
</style>
