
import {
  readOverlayStudies,
  readPanelStudies,
  StudyConfig,
} from '@/modules/market/components/chart/chartConfig'
import * as R from 'ramda'
import { defineComponent, shallowRef } from 'vue'
import Pic from '@/components/Pic.vue'

export default defineComponent({
  name: 'SelectDialog',
  components: {
    Pic,
  },
  props: {
    active: Number,
  },
  setup (props, ctx) {
    const all = readOverlayStudies().concat(readPanelStudies())
    const studies = R.reject(R.propSatisfies(v => v === false, 'configurable'), all)

    const select = shallowRef<StudyConfig>(studies[0])

    const handleSelect = (item: StudyConfig) => {
      select.value = item
      ctx.emit('update:study', item)
    }

    handleSelect(select.value)

    return {
      studies,
      select,
      handleSelect,
    }
  },
})
